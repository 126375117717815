import React, { useState } from 'react'
import { AiOutlineHeart } from 'react-icons/ai';
import { AiOutlineExpand } from 'react-icons/ai';
import { FaExchangeAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import MyVerticallyCenteredModal from '../Modal';

const GalleryList = (props) => {
    let dispatch = useDispatch();
    console.log(props);
    // Add to cart
    const addToCart = async (id) => {
        dispatch({ type: "products/addToCart", payload: { id } })
    }
    // Add to Favorite
    const addToFav = async (id) => {
        dispatch({ type: "products/addToFav", payload: { id } })
    }
    // Add to Compare
    const addToComp = async (id) => {
        dispatch({ type: "products/addToComp", payload: { id } })
    }
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className="product_wrappers_one">
                <div className="thumb">
                    <Link to={`/gallery-details-one/${props.data.id}`} className="image">
                        <img src={props.data.imageURL.length ? props.data.imageURL[0].imageURL :''} alt="Product" />
                        <img className="hover-image" src={props.data.imageURL.length ? props.data.imageURL[0].imageURL :''}
                            alt="Product" />
                    </Link>
                    <span className="badges">
                        <span className={(['hot','new','sale'][Math.round(Math.random()*2)])}>{props.data.approvalStatus}</span>
                    </span>
                    <div className="actions">
                        <a href="#!" className="action wishlist" title="Wishlist" onClick={() => addToFav(props.data.id)}><AiOutlineHeart /></a>
                        <a href="#!" className="action quickview" title="Quick view" onClick={() => setModalShow(true)}><AiOutlineExpand /></a>
                        <a href="#!" className="action compare" title="Compare" onClick={() => addToComp(props.data.id)}><FaExchangeAlt /></a>
                    </div>
                    {/* <button type="button" className="add-to-cart offcanvas-toggle" onClick={() => addToCart(props.data.id)}>Buy</button> */}
                </div>
                <div className="content">
                    <h5 className="title">
                        <Link to={`/gallery-details-one/${props.data.id}`}>{props.data.title}</Link>
                    </h5>
                    <span className="price">
                        <span className="new">₹{props.data.price}.00</span>
                    </span>
                </div>
            </div>

            {/* <MyVerticallyCenteredModal data={props.data} show={modalShow} onHide={() => setModalShow(false)} /> */}
        </>
    )
}

export default GalleryList